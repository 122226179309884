<template>
  <div class="product-images">
    <v-row no-gutters>
      <v-col
        style="position: relative;"
        :cols="images.length > 1 ? 9 : 12"
        sm="12"
        order="1"
        class="d-flex justify-center"
      >
        <v-btn
          color="default"
          class="zoom_in_cta"
          icon
          @click.stop="dialog = true"
        >
          <v-icon large>$zoomIn</v-icon>
        </v-btn>

        <div class="image-block-wrapper">
          <img
            class="image-mobile"
            v-if="$vuetify.breakpoint.xs"
            :src="selectedImage.medium"
            :alt="`Immagine prodotto  ${name}`"
            :title="`Immagine prodotto  ${name}`"
            @click.stop="dialog = true"
            onerror="this.onerror=null;this.src='/no-icon.png'"
          />
          <zoom-on-hover
            v-else
            :scale="1.5"
            :img-normal="selectedImage.medium"
            :img-zoom="selectedImage.large"
            :altText="`Immagine prodotto  ${name}`"
          ></zoom-on-hover>
        </div>
      </v-col>

      <v-col
        cols="3"
        sm="12"
        order="2"
        class="my-8 d-flex justify-center thumb-wrapper"
        v-if="images.length > 1"
      >
        <div class="swiper-button-next" slot="button-next"></div>
        <swiper :options="swiperOptionThumbs" class="swiper" ref="swiperThumb">
          <swiper-slide v-for="media in images" :key="media.medium">
            <img
              :key="'img_' + media.medium"
              :src="media.small"
              :alt="'Anteprima immagine prodotto ' + name"
              :class="{ selected: selectedImage == media }"
              @click="selectedImage = media"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" slot="button-prev"></div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="zoom-dialog"
    >
      <div class="zoom-dialog-content mt-4">
        <v-row no-gutters justify="center">
          <v-btn
            class="rounded-pill"
            color="primary"
            large
            @click.stop="dialog = false"
            width="250"
            depressed
            >{{ $t("common.close") }}</v-btn
          >
        </v-row>
        <pinch-zoom height="100%" class="mt-6">
          <img :src="selectedImage.large" />
        </pinch-zoom>
      </div>
      <div
        class="d-flex flex-row zoom-swiper-wrapper mt-6"
        v-if="images.length > 1"
      >
        <swiper :options="swiperOptionThumbs" class="swiper">
          <swiper-slide v-for="media in images" :key="media.medium">
            <img
              :key="'img_' + media.medium"
              :src="media.small"
              :alt="'Anteprima immagine prodotto ' + name"
              :class="{ selected: selectedImage == media }"
              @click="selectedImage = media"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </swiper-slide>
        </swiper>
      </div>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.product-images {
  .image-block-wrapper {
    width: 360px;
    text-align: center;
    .image-mobile {
      max-width: 100%;
    }
  }
  .zoom_in_cta {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    background: transparent;
    // top: 25px;
    &::after {
      font-size: 12px;
      background-color: transparent;
      color: var(--v-secondary-lighten1);
      padding: 0;
    }
  }

  .thumb-wrapper {
    margin: 0;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    .swiper-slide {
      height: 100px;
      width: 100px;
    }
    img {
      padding: 5px;
      width: 100px;
      height: 100px;
      max-width: 105px;
      max-height: 105px;
      //  border: 1px solid #d5d5d5;
      border-radius: 8px;
      cursor: pointer;
      &.selected {
        border: 1px solid #343f59;
        //border-color: ;
        // cursor: default;
      }
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      .swiper-container {
        width: 75%;
        max-width: 75%;
      }
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      .swiper-slide {
        height: 75px;
        width: 75px;
      }
      img {
        width: 75px;
        height: 75px;
        max-width: 75px;
        max-height: 75px;
      }
    }
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    width: 12px !important;
    height: 12px !important;
  }
  .swiper-button-next {
    right: 0;
    left: auto;
    top: 50%;
    transform: translateX(10px);
    &::after {
      //  background-color: $white;
      color: var(--v-default-base);
    }
  }
  .swiper-button-prev {
    left: 0;
    right: auto;
    top: 50%;
    transform: translateX(10px);
    &::after {
      //   background-color: $white;
      color: var(--v-defaukt-base);
    }
  }
}
.swiper-button-disabled {
  opacity: 0.5 !important;
  transition: opacity 0.2s ease-in-out;
}

.zoom-dialog {
  background-color: black;
}
.zoom-dialog-content {
  .pinch-zoom-wrapper {
    background-color: white !important;
    max-width: 95% !important;
    margin: 0 auto;
    img {
      border: 1px solid var(--v-grey-lighten3);
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .zoom-dialog-content {
    .pinch-zoom-wrapper {
      img {
        width: 500px;
        max-width: 500px;
        height: auto;
        object-fit: contain;
      }
    }
  }
}
.zoom-swiper-wrapper {
  .swiper-slide {
    width: initial;
    img {
      padding: 5px;
      width: 80px;
      height: 80px;
      border: 1px solid var(--v-grey-lighten3);
      border-radius: 8px;
      &.selected {
        border-color: var(--v-primary-base);
      }
    }
  }
}
</style>
<script>
import PinchZoom from "vue-pinch-zoom";

export default {
  name: "ProductImages",
  props: {
    images: { type: Array, required: true },
    name: { type: String, required: false }
  },
  components: { PinchZoom },
  data() {
    return {
      selectedImage: {},
      dialog: false,
      swiperOptionThumbs: {
        direction: this.$vuetify.breakpoint.xsOnly ? "vertical" : "horizontal",
        slidesPerView: "auto",
        spaceBetween: 10,
        watchOverflow: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  mounted() {
    this.selectedImage = this.images[0];
  },
  watch: {
    "$vuetify.breakpoint.xsOnly": function(value) {
      // this.$refs.swiperThumb.$swiper.params.direction = value
      //   ? "vertical"
      //   : "horizontal";
      this.swiperOptionThumbs.direction = value ? "vertical" : "horizontal";
      this.$refs.swiperThumb.$swiper.destroy();
      this.$refs.swiperThumb.$swiper.init();
      console.log("xs changed" + value);
    }
  }
};
</script>
